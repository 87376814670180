// extracted by mini-css-extract-plugin
export var actualLink = "styles-module--actualLink--3CI6-";
export var fauxLink = "styles-module--fauxLink--3NhR3";
export var textWidth = "styles-module--textWidth--2_39U";
export var pageHeader = "styles-module--pageHeader--1vu7p";
export var pageTitle = "styles-module--pageTitle--1RIAA";
export var pageSubTitle = "styles-module--pageSubTitle--1fUdz";
export var unset = "styles-module--unset--3IfeE";
export var resetList = "styles-module--resetList--oQ4q8";
export var tac = "styles-module--tac--2-aau";
export var tal = "styles-module--tal--1T_F3";
export var tar = "styles-module--tar--3r3CB";
export var noBoxShadow = "styles-module--no-box-shadow--1HlD0";
export var ptn = "styles-module--ptn--xYTd_";
export var pvn = "styles-module--pvn--1UipI";
export var pan = "styles-module--pan--2L3Xp";
export var ptxs = "styles-module--ptxs--TeXeL";
export var pvxs = "styles-module--pvxs--biU_F";
export var paxs = "styles-module--paxs--1b4fy";
export var pts = "styles-module--pts--1ZBzb";
export var pvs = "styles-module--pvs--24yP-";
export var pas = "styles-module--pas--OblBu";
export var ptm = "styles-module--ptm--3g3Gb";
export var pvm = "styles-module--pvm--2iRVs";
export var pam = "styles-module--pam--zUl2c";
export var ptl = "styles-module--ptl--3sYYI";
export var pvl = "styles-module--pvl--1FgmX";
export var pal = "styles-module--pal--32vo_";
export var ptxl = "styles-module--ptxl--3fWx4";
export var pvxl = "styles-module--pvxl--16Anm";
export var paxl = "styles-module--paxl--1H52R";
export var prn = "styles-module--prn--1iOgv";
export var phn = "styles-module--phn--3aU-A";
export var prxs = "styles-module--prxs--1jYZT";
export var phxs = "styles-module--phxs--wp9jX";
export var prs = "styles-module--prs--bvmo3";
export var phs = "styles-module--phs--1ZcvA";
export var prm = "styles-module--prm--2iSYA";
export var phm = "styles-module--phm--3aClG";
export var prl = "styles-module--prl--3e_Jy";
export var phl = "styles-module--phl--24ZEV";
export var prxl = "styles-module--prxl--1vdOo";
export var phxl = "styles-module--phxl--2L1_V";
export var pbn = "styles-module--pbn--XH7QW";
export var pbxs = "styles-module--pbxs--3OYfl";
export var pbs = "styles-module--pbs--2rRtJ";
export var pbm = "styles-module--pbm--3QEn1";
export var pbl = "styles-module--pbl--3swmJ";
export var pbxl = "styles-module--pbxl--36bF5";
export var pln = "styles-module--pln--ASqOl";
export var plxs = "styles-module--plxs--1D3Rk";
export var pls = "styles-module--pls--3Bevl";
export var plm = "styles-module--plm--3w6n4";
export var pll = "styles-module--pll--a6aAm";
export var plxl = "styles-module--plxl--1iY53";
export var mtn = "styles-module--mtn--3qvFh";
export var mvn = "styles-module--mvn--2W8Gc";
export var man = "styles-module--man--vOUcv";
export var mtxs = "styles-module--mtxs--9MEkb";
export var mvxs = "styles-module--mvxs--1zIJi";
export var maxs = "styles-module--maxs--3scbe";
export var mts = "styles-module--mts--34Okt";
export var mvs = "styles-module--mvs--1he82";
export var mas = "styles-module--mas--waBXR";
export var mtm = "styles-module--mtm--1VsOw";
export var mvm = "styles-module--mvm--1tBB5";
export var mam = "styles-module--mam--iqg7A";
export var mtl = "styles-module--mtl--3FEA8";
export var mvl = "styles-module--mvl--2Us_8";
export var mal = "styles-module--mal--23dCQ";
export var mtxl = "styles-module--mtxl--21JWX";
export var mvxl = "styles-module--mvxl--I6nQw";
export var maxl = "styles-module--maxl--2eUuq";
export var mrn = "styles-module--mrn--2Xv-n";
export var mhn = "styles-module--mhn--1huFj";
export var mrxs = "styles-module--mrxs--2om_J";
export var mhxs = "styles-module--mhxs--2Tb1a";
export var mrs = "styles-module--mrs--1Ud42";
export var mhs = "styles-module--mhs--J9Evd";
export var mrm = "styles-module--mrm--3cxcH";
export var mhm = "styles-module--mhm--94xn8";
export var mrl = "styles-module--mrl--2D_Fv";
export var mhl = "styles-module--mhl--ccpK5";
export var mrxl = "styles-module--mrxl--1tpyF";
export var mhxl = "styles-module--mhxl--1uI_1";
export var mbn = "styles-module--mbn--1ckDq";
export var mbxs = "styles-module--mbxs--3IDRg";
export var mbs = "styles-module--mbs--2f2az";
export var mbm = "styles-module--mbm--muB0F";
export var mbl = "styles-module--mbl--1H97_";
export var mbxl = "styles-module--mbxl--1r6ew";
export var mln = "styles-module--mln--3OBSy";
export var mlxs = "styles-module--mlxs--Cw7Za";
export var mls = "styles-module--mls--3IPY9";
export var mlm = "styles-module--mlm--3IBKf";
export var mll = "styles-module--mll--3kfhL";
export var mlxl = "styles-module--mlxl--1eils";
export var headshotWrapper = "styles-module--headshotWrapper--36GUM";
export var headshot = "styles-module--headshot--1oLbG";
export var aboutHeader = "styles-module--aboutHeader--1VK29";